import React, { useState } from 'react';
import { MDBNavbar, MDBContainer, MDBNavbarToggler, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBBtn } from 'mdb-react-ui-kit';
import { FaBars, FaTimes, FaInfoCircle, FaChartLine, FaBoxOpen, FaUsers, FaEnvelope, FaCalculator } from 'react-icons/fa';
import { RiContractFill } from "react-icons/ri";
import '../styles/navbar.css';
import logo from '../img/logo/Ativo 50.png';

const Navbar = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    return (
        <>
            <MDBNavbar expand='lg' light className='navbar-main'>
                <MDBContainer fluid>
                    <div className="only-mobile-flex justify-content-between" style={{ width: '100%' }}>
                        <div className="navbar-logo d-lg-none ms-3">
                            <img src={logo} alt="Logo" height="40" />
                        </div>
                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded={modalOpen}
                            aria-label='Toggle navigation'
                            onClick={toggleModal}
                            className="text-white"
                            style={{ zIndex: 10 }}
                        >
                            <FaBars />
                        </MDBNavbarToggler>
                    </div>

                    <div className="d-none d-lg-flex collapse-content">
                        <div className="navbar-log ms-3">
                            <img src={logo} alt="Logo" height="40" />
                        </div>
                        <MDBNavbarNav className='me-auto mb-2 mb-lg-0'>
                            <MDBNavbarItem>
                                <MDBNavbarLink className='navbar-item' href='/#garantia'>O que é Seguro GE</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink className='navbar-item' href='/#vender'>Por que Vender</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink className='navbar-item' href='/#produtos'>Produtos Elegíveis</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink className='navbar-item' href='/#quemSomos'>Quem Somos</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink className='navbar-item' href='/#queroFalar'>Contato</MDBNavbarLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                        <MDBBtn className='navbar-button ms-auto' href="/#simulacao">Simulação</MDBBtn>
                    </div>
                </MDBContainer>
            </MDBNavbar>

            {modalOpen && (
                <div className="custom-modal">
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <MDBBtn className='btn-close' color='none' onClick={toggleModal}>
                                <FaTimes />
                            </MDBBtn>
                        </div>
                        <div className="custom-modal-body">
                            <div className="modal-logo">
                                <img src={logo} alt="Logo" />
                            </div>
                            <MDBNavbarNav className='me-auto mb-2 mb-lg-0'>
                                <MDBNavbarItem>
                                    <MDBNavbarLink className='navbar-item' href='/#garantia' onClick={toggleModal}>
                                        <RiContractFill className='me-2' /> O que é Seguro GE
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink className='navbar-item' href='/#vender' onClick={toggleModal}>
                                        <FaChartLine className='me-2' /> Por que Vender
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink className='navbar-item' href='/#produtos' onClick={toggleModal}>
                                        <FaBoxOpen className='me-2' /> Produtos Elegíveis
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink className='navbar-item' href='/#quemSomos' onClick={toggleModal}>
                                        <FaUsers className='me-2' /> Quem Somos
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink className='navbar-item' href='/#queroFalar' onClick={toggleModal}>
                                        <FaEnvelope className='me-2' /> Contato
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                            <MDBBtn className='navbar-button' href="/#simulacao" onClick={toggleModal}>
                                <FaCalculator className='me-2' /> Simulação
                            </MDBBtn>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Navbar;
