import React, { useState, useEffect } from 'react';
import '../styles/cookieNotice.css';

function CookieNotice() {
    const [showNotice, setShowNotice] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setShowNotice(true);
        }
    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowNotice(false);
    };

    if (!showNotice) {
        return null;
    }

    return (
        <div className="cookie-notice">
            <p>Utilizamos cookies para oferecer uma melhor experiência em nosso site, melhor desempenho e conteúdo personalizado. Ao utilizar o site, você também concorda com nossaPolítica de Privacidade.</p>
            <button onClick={handleAcceptCookies}>Ok, Entendi</button>
        </div>
    );
}

export default CookieNotice;
