import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import { saveSimulation } from '../api_controller/submitData';
import '../styles/parceria.css';
import Shop from '../img/parceria/shop.svg';
import Tools from '../img/parceria/tools.svg';

function Parceria() {
  const [showContact, setShowContact] = useState(false);
  const [faixaVendas, setFaixaVendas] = useState('');
  const [precoMedio, setPrecoMedio] = useState('');
  const [quantidadeVendas, setQuantidadeVendas] = useState('');
  const [receitaInicial, setReceitaInicial] = useState(0);
  const [receitaFinal, setReceitaFinal] = useState(0);
  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState('Simular');
  const [isSimulated, setIsSimulated] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [formError, setFormError] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    if (emailError || formError) {
      if (validateEmail(email) && faixaVendas && precoMedio && quantidadeVendas) {
        setEmailError('');
        setFormError('');
      }
    }
  }, [email, faixaVendas, precoMedio, quantidadeVendas]);

  const handleSimulate = async () => {
    if (!email) {
      setEmailError('Por favor, preencha o campo de e-mail.');
      return;
    }

    if (!validateEmail(email)) {
      setEmailError('Por favor, insira um e-mail válido.');
      return;
    }

    if (!faixaVendas || !precoMedio || !quantidadeVendas) {
      setFormError('Por favor, selecione todas as opções.');
      return;
    }

    setEmailError('');
    setFormError('');

    let valorInicial, valorFinal;

    switch (faixaVendas) {
      case 'Até 100 mil reais':
        valorInicial = 0;
        valorFinal = 100000;
        break;
      case 'De 100 mil a 200 mil reais':
        valorInicial = 100000;
        valorFinal = 200000;
        break;
      case 'De 200 mil a 400 mil reais':
        valorInicial = 200000;
        valorFinal = 400000;
        break;
      case 'Acima de 400 mil reais':
        valorInicial = 400000;
        valorFinal = 500000; // Assume um valor arbitrário acima de 400 mil
        break;
      default:
        valorInicial = 0;
        valorFinal = 0;
    }

    const receitaInicialCalc = valorInicial * 0.01575;
    const receitaFinalCalc = valorFinal * 0.013125;

    setReceitaInicial(receitaInicialCalc);
    setReceitaFinal(receitaFinalCalc);

    setShowContact(true);
    setIsSimulated(true);
    setButtonText('Realizar nova simulação');

    // Enviar os dados da simulação para o backend
    try {
      const response = await saveSimulation({
        faixaVendas,
        precoMedio,
        quantidadeVendas,
        receitaInicial: receitaInicialCalc,
        receitaFinal: receitaFinalCalc,
        email
      });
      console.log(response);
    } catch (error) {
      console.error('Erro ao salvar a simulação:', error);
    }
  };

  const handleNewSimulation = () => {
    setFaixaVendas('');
    setPrecoMedio('');
    setQuantidadeVendas('');
    setReceitaInicial(0);
    setReceitaFinal(0);
    setEmail('');
    setShowContact(false);
    setIsSimulated(false);
    setButtonText('Simular');
    setEmailError('');
    setFormError('');
  };

  return (
    <MDBContainer className="parceria-section" id='parceria'>
      <h2>Para quem é essa Oferta?</h2>
      <p>Nossa parceria é ideal para vários participantes de diversos segmentos do varejo. Abaixo, listamos algumas das muitas categorias que se encaixam nessa oferta.</p>
      <MDBRow className='parceria-card-wrapper' id='simulacao'>
        <MDBCol lg="5" className='parceria-card'>
          <MDBCard>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size={9}>
                  <MDBCardTitle>Donos de Loja</MDBCardTitle>
                  <MDBCardText>Vendedores de eletrodomésticos, móveis, materiais de jardinagem, computadores, smartphone, eletrônicos e diversos outros setores.</MDBCardText>
                </MDBCol>
                <MDBCol size={3} className='d-flex justify-content-center align-items-center'>
                  <img src={Shop} className='parceria-card-icon' />
                  <div className='parceria-card-bg'></div>
                  <div className='parceria-card-bg2'></div>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="5" className='parceria-card'>
          <MDBCard>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size={9}>
                  <MDBCardTitle>Distribuidoras e Assistências Técnicas</MDBCardTitle>
                  <MDBCardText>Distribuidoras, como de ar condicionado ou equipamentos de academia e prestadores de serviços.</MDBCardText>
                </MDBCol>
                <MDBCol size={3} className='d-flex justify-content-center align-items-center'>
                  <img src={Tools} className='parceria-card-icon' />
                  <div className='parceria-card-bg'></div>
                  <div className='parceria-card-bg2'></div>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <h3 className='mt-4'>Faça uma simulação:</h3>
      <h6 className='mt-2 mb-4'>Informe o volume de vendas mensais estimado de sua loja e simule a receita adicional da venda do seguro garantia estendida.</h6>
      <MDBRow className='parceria-card-wrapper'>
        <MDBCol md="12" className='parceria-sim-card'>
          <MDBCard>
            <div className='parceria-card-sim-bg'></div>
            <div className='parceria-card-sim-bg2'></div>
            <MDBCardBody>
              <MDBRow>
                <MDBCol md="4">
                  <select className="form-select" value={faixaVendas} onChange={(e) => setFaixaVendas(e.target.value)}>
                    <option value="">Faixa de vendas por mês</option>
                    <option value="Até 100 mil reais">Até 100 mil reais</option>
                    <option value="De 100 mil a 200 mil reais">De 100 mil a 200 mil reais</option>
                    <option value="De 200 mil a 400 mil reais">De 200 mil a 400 mil reais</option>
                    <option value="Acima de 400 mil reais">Acima de 400 mil reais</option>
                  </select>
                </MDBCol>
                <MDBCol md="4">
                  <select className="form-select" value={precoMedio} onChange={(e) => setPrecoMedio(e.target.value)}>
                    <option value="">Preço médio dos produtos</option>
                    <option value="R$ 100 - R$ 500">R$ 100 - R$ 500</option>
                    <option value="R$ 501 - R$ 1.000">R$ 501 - R$ 1.000</option>
                    <option value="R$ 1.001 - R$ 5.000">R$ 1.001 - R$ 5.000</option>
                    <option value="Acima de 5 mil reais">Acima de 5 mil reais</option>
                  </select>
                </MDBCol>
                <MDBCol md="4">
                  <select className="form-select" value={quantidadeVendas} onChange={(e) => setQuantidadeVendas(e.target.value)}>
                    <option value="">Quantidade média de vendas</option>
                    <option value="Até 100 equipamentos">Até 100 equipamentos</option>
                    <option value="De 100 à 200 equipamentos">De 100 à 200 equipamentos</option>
                    <option value="De 200 a 400 equipamentos">De 200 a 400 equipamentos</option>
                    <option value="Acima 400 equipamentos">Acima 400 equipamentos</option>
                  </select>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="12">
                  <MDBInput className='mail-input' label="Digite seu e-mail" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  {emailError && <p className="text-warn">{emailError}</p>}
                  {formError && <p className="text-warn">{formError}</p>}
                </MDBCol>
              </MDBRow>
              <MDBRow className="align-items-center">
                <MDBCol md="12" className='mt-3'>
                  <MDBBtn className='sim-btn' color="primary" onClick={isSimulated ? handleNewSimulation : handleSimulate}>
                    {buttonText}
                  </MDBBtn>
                  {showContact && <a href='#queroFalar' className="ml-2 sim-ctt-btn btn">Entrar em Contato</a>}
                </MDBCol>
                <MDBCol md="12" className="text-right mt-3">
                  <h3>Receita Adicional <br /> <b><span className='aproximadamente-tip'>Aproximadamente</span> {receitaInicial > 0 ? `De ${receitaInicial.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} à ` : null}{receitaFinal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></h3>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Parceria;
