import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import '../styles/hero.css'
import heroImg from '../img/hero/hero-img.svg'

function Hero() {
    return (
        <>
            <MDBContainer fluid className="hero-section" id='inicio'>
                <MDBRow className="align-items-center" sty>
                    <MDBCol md="7">
                        <p className='hero-top-text'>Cliente <b>protegido</b> e <b>feliz</b>! É cliente <b>fiel</b>!</p>
                        <h1>Seguro Garantia Estendida</h1>
                        <h4>Venda também o seguro garantia estendida e aumente a margem de seu negócio.</h4>
                        <p>Ao oferecer o seguro garantia estendida em sua loja, você traz proteção para o seu cliente  e ainda aumenta sua receita sem qualquer custo adicional!</p>
                        <MDBBtn href='#queroFalar' color="primary">Seja Nosso parceiro</MDBBtn>
                    </MDBCol>
                    <MDBCol md="5" className='d-flex justify-content-center'>
                        <img src={heroImg} alt="Placeholder" className="img-fluid" />
                    </MDBCol>
                </MDBRow>
                <div className='hero-curve'><svg viewBox="0 50 1440 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1.17961H1440V96.4114C1440 96.4114 1203.5 190.032 720 129.602C236.5 69.1725 0 96.4114 0 96.4114L0 1.17961Z" fill="#E68324" />
                    <path d="M0 0.865967H1440V81.8386C1440 81.8386 1203.5 175.459 720 115.029C236.5 54.5997 0 81.8386 0 81.8386L0 0.865967Z" fill="#1D4284" />
                </svg></div>
            </MDBContainer>
        </>
    );
}

export default Hero;
