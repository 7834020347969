import React from 'react';
import '../styles/privacidade.css';

function Privacidade() {
    return (
        <div className="privacidade-container" id='garantia'>
            <h1>Política de Privacidade</h1>
            <p>Última atualização em 18 de julho de 2024.</p>
            <p>Esta POLÍTICA DE PRIVACIDADE (“Política”) descreve como a Plug Promotora de Vendas
                (“PLUG”), inscrita no CNPJ no. 26.211.588/0001-16, trata e tratará as informações confidenciais de
                Usuários durante a utilização do seu endereço www.plugseguros.com.br e de quaisquer outros
                endereços web (“Sites”) ou aplicativos relacionados (“Aplicativos”) administrados pela PLUG. Esta
                Política descreve como coletamos, utilizamos, compartilhamos e protegemos os Dados Pessoais dos
                Usuários dos Sites e dos Aplicativos. As regras deste documento serão aplicáveis sempre que
                qualquer Usuário navegar nos Sites ou utilizar os Aplicativos.
            </p>
            <h2>FINALIDADE</h2>
            <p>A finalidade principal para a qual coletamos Dados Pessoais é para fornecer uma
                experiência segura, tranquila, eficiente e personalizada para você, Usuário, com qualidade e dentro
                dos requisitos regulatórios exigidos, como a Lei Geral de Proteção de Dados nº 13.709, de 14 de
                agosto de 2018 (“LGPD”). Para isso, coletamos e tratamos os Dados Pessoais para que possamos:</p>
            <ul>
                <li>Fornecer nossos serviços de forma personalizada, sempre com o propósito de melhorá-los;</li>
                <li>Manter contato com os clientes para tratar assuntos relacionados à prestação dos serviços;</li>
                <li>Fornecer suporte aos nossos clientes;</li>
                <li>Realizar ações de marketing direcionado, enviar avisos de atualização no serviço e ofertas promocionais com base nas suas preferências de comunicação;</li>
                <li>Realizar pesquisa de satisfação e feedback sobre os nossos serviços e iniciativas;</li>
                <li>Impedir atividades potencialmente proibidas ou ilegais, inclusive prevenção de fraudes;</li>
                <li>Cumprir com obrigações legais ou regulatórias, ou seja, seus Dados Pessoais poderão ser utilizados para o atendimento de obrigações dispostas em lei e/ou regulações de órgãos governamentais e de ordens ou requisições de autoridades fiscais, Poder Judiciário e/ou outra autoridade competente.</li>
            </ul>
            <h2>COMO COLETAMOS SEUS DADOS PESSOAIS</h2>
            <p>Seus dados pessoais podem chegar até nós através das situações descritas abaixo:</p>
            <ul>
                <li>Dados fornecidos diretamente de forma voluntária por você ou por seu representante legal quando contrata nossos serviços ou entra em contato conosco através de nossos canais de atendimento, e-mail, chat ou outras ferramentas;</li>
                <li>Seus Dados Pessoais podem ser obtidos através de terceiros como consultores, prestadores de serviços e fornecedores de produtos que nos auxiliam a oferecer um serviço cada vez melhor para você;</li>
                <li>Dados obtidos automaticamente por meio dos dispositivos que você utiliza para acessar nossos Sites ou Aplicativos. Esses dados, coletados através de cookies ou tecnologias similares, nos auxiliam a gerar estatísticas de acesso e, assim, conhecer melhor nossos Usuários para que possamos aperfeiçoar nossos serviços e adequá-los aos mais diversos públicos.</li>
            </ul>
            <h2>DADOS PESSOAIS QUE COLETAMOS E TRATAMOS</h2>
            <p>Para que possamos prestar nossos serviços com qualidade ao longo do nosso relacionamento com você, podemos coletar diferentes tipos de Dados Pessoais. Com objetivo de respeitar sua privacidade, nos comprometemos a coletar e tratar somente os dados pessoais necessários para a finalidade descrita nessa Política, sempre limitando a coleta ao mínimo necessário. Em contrapartida, você concorda expressamente em fornecer apenas Dados Pessoais precisos e verdadeiros, assumindo a responsabilidade em caso de fornecimento em nossos Sites e Aplicativos de informações falsas, desatualizadas ou imprecisas.</p>
            <ul>
                <li>Dados cadastrais: nome completo, data de nascimento, CPF, RG, endereço residencial e/ou comercial, sexo, estado civil, nacionalidade, profissão, dados de cônjuges, ascendentes (pai e mãe) e de descendentes (filhos e enteados);</li>
                <li>Dados de contato: telefone fixo e/ou celular, e-mails pessoal e/ou profissional, contas em aplicativos de mensagens e de redes sociais;</li>
                <li>Dados financeiros e de pagamento: contas correntes, chaves PIX, dados bancários, dados de cartão de crédito/débito, dados sobre a renda e dados relativos à situação de crédito no mercado;</li>
                <li>Dados de identificação digital: Endereço IP, histórico de navegação em nossos Sites e Aplicativos, incluindo dados técnicos, como informações de URL, cookies, navegador e dados do dispositivo eletrônico, entre eles, ID de dispositivo, sistema operacional, modelo e dados de geolocalização. Vale destacar que a PLUG não realiza coleta nem tratamento de Dados Pessoais Sensíveis.</li>
            </ul>
            <h2>COLETA AUTOMÁTICA E COOKIES</h2>
            <p>Os cookies são arquivos de texto simples que são automaticamente colocados no seu navegador ou dispositivo pelos sites que você visita. Eles são importantes e amplamente usados para fazer com que os sites funcionem ou funcionem com mais eficiência, além de fornecer informações aos proprietários do site. Os cookies e tecnologias similares (web beacons, pixels e outros) são utilizados pela PLUG para finalidades tais como:</p>
            <ul>
                <li>Autenticação do Usuário;</li>
                <li>Gravação de preferências e configurações;</li>
                <li>Avaliação de popularidade do conteúdo oferecido;</li>
                <li>Mensuração de efetividade de campanhas de marketing;</li>
                <li>Análise do comportamento online do Usuário quando interage com os serviços oferecidos.</li>
            </ul>
            <p>Com exceção dos cookies essenciais, que são obrigatórios para o funcionamento do site, você não é obrigado a autorizar a utilização de todos os cookies. Mas é importante saber que caso você opte por não autorizar alguns ou todos os cookies, algumas funcionalidades do site podem não funcionar corretamente. Por isso é que recomendamos que você sempre autorize todos os cookies para se ter uma melhor e completa experiência em nossos Sites. E você sempre poderá ajustar suas preferências através do link (“Configuração de Cookies“) localizado no canto inferior direito da página.</p>
            <h2>COMO UTILIZAMOS SEUS DADOS PESSOAIS</h2>
            <p>Podemos tratar seus dados pessoais para diversas finalidades específicas, entre as quais:</p>
            <ul>
                <li>Identificar você e viabilizar seu acesso a nossos serviços;</li>
                <li>Entrar em contato com você quando julgarmos ser necessário para a prestação de nossos serviços;</li>
                <li>Aprimorar, comercializar ou anunciar nossos serviços quando entendermos ser do seu interesse;</li>
                <li>Enviar conteúdos publicitários e de marketing por e-mail, telefone, mensagens de texto ou outros meios, exceto quando você nos instruir expressamente para não o contatar para este fim;</li>
                <li>Para realização de assinatura ou de ações preliminares a um contrato ou proposta de um produto ou serviço fornecidos diretamente por nós ou por nossos parceiros;</li>
                <li>Viabilizar processos de pagamento pelos serviços adquiridos em nossos Sites e Aplicativos;</li>
                <li>Viabilizar proteção ao crédito, em caso de não pagamento dos serviços adquiridos, para entrar em contato com você ou para realizar a inscrição em órgãos de proteção ao crédito, caso se torne necessário;</li>
                <li>Enviar comunicados e pesquisas de satisfação como parte do nosso processo de melhoria dos nossos serviços prestados;</li>
                <li>Cumprir obrigações legais ou regulatórias as quais estamos submetidos;</li>
                <li>Evitar, investigar e/ou desvendar fraudes, de forma, a zelar pela sua segurança e de terceiros.</li>
            </ul>
            <h2>COMPARTILHAMENTO DE SEUS DADOS PESSOAIS</h2>
            <p>A PLUG pode compartilhar os Dados Pessoais estritamente necessários para atender às finalidades específicas para a prestação de serviços a você. Desta forma, seus Dados Pessoais podem ser compartilhados nas seguintes hipóteses:</p>
            <ul>
                <li>Sempre que estiver obrigado por ato de autoridade competente, ordem ou mandado judicial;</li>
                <li>Com órgãos da administração pública para o cumprimento de obrigação legal ou regulatória;</li>
                <li>Com nossos parceiros comerciais e/ou fornecedores de produtos e/ou prestadores de serviços, entre os quais, mas não limitados a: agentes comerciais, corretores de seguros, agências de marketing, financeiras, bancos, seguradoras, serviços de assistência 24 horas, serviços de atendimento, empresas de tecnologia, serviços de hospedagem de sites e dados em nuvem, birôs de crédito, serviços de adquirência, pagamento e cobrança;</li>
                <li>Com órgãos de proteção e defesa de crédito;</li>
                <li>Com órgãos que administrem cadastros de consumidores;</li>
                <li>Com controladores da PLUG, com empresas por ela controlada, com empresas a ela coligada ou por qualquer forma a ela associada, no Brasil ou no exterior;</li>
                <li>Em caso de fusão, aquisição (total ou parcial) e incorporação.</li>
            </ul>
            <h2>COMO PROTEGEMOS SEUS DADOS PESSOAIS</h2>
            <p>A PLUG utiliza medidas de segurança, incluindo políticas e tecnologias, que considera adequadas para proteger seus dados pessoais, tais como, mas não se limitando a: redes privadas, recursos de firewall, controle de acesso as bases de dados, recursos de criptografia, processos de monitoramento de rede de comunicação e de bases de dados, entre outras.</p>
            <h2>ARMAZENAMENTO E RETENÇÃO DOS DADOS PESSOAIS</h2>
            <p>Os Dados Pessoais coletados são armazenados em nossos servidores localizados no Brasil, bem como em ambiente de uso de recursos e serviços de hospedagem em nuvem. Os Dados Pessoais somente serão mantidos pelo tempo que for necessário para cumprir com as finalidades para as quais estes foram coletados, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, de prestação de contas ou requisição de autoridades competentes.</p>
            <h2>CONTATO</h2>
            <p>Caso você necessite entrar em contato para esclarecer dúvidas, reclamar sobre assuntos relacionados ao tratamento de seus Dados Pessoais ou exercer seus direitos dispostos na LGPD, você pode entrar em contato com a PLUG através de um de nossos canais de atendimento em nossos Sites ou Aplicativos, ou enviar um e-mail para privacidade@plugseguros.com.br.</p>
            <h2>GLOSSÁRIO</h2>
            <ul>
                <li><strong>Dados Pessoais:</strong> São as informações relativas a uma pessoa natural identificada ou que permitem a sua identificação, direta ou indiretamente. Além de informações básicas de identificação, a exemplo de nome, número de identificação, dados de localização, são também considerado Dados Pessoais outros dados que estejam relacionados com uma pessoa natural, tais como seus hábitos de consumo, sua aparência e aspectos de sua personalidade.</li>
                <li><strong>Dados Pessoais Sensíveis:</strong> Qualquer Dado Pessoal que diga respeito à origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, bem como dado referente à saúde ou à vida sexual, dado genético ou biométrico.</li>
                <li><strong>LGPD:</strong> Lei Geral de Proteção de Dados nº 13.709, de 14 de agosto de 2018.</li>
                <li><strong>Tratamento:</strong> É qualquer atividade realizada com Dados Pessoais e/ou Dados Pessoais Sensíveis, como coleta, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, atualização, comunicação, transferência, compartilhamento e extração.</li>
                <li><strong>Usuários:</strong> Para efeito desta Política, significa toda pessoa física que acessar os Sites ou Aplicativos, entre eles, mas não exclusivamente, visitantes, clientes, parceiros, colaboradores, fornecedores de produtos ou prestadores de serviços.</li>
            </ul>
            <h2>ALTERAÇÕES DO DOCUMENTO</h2>
            <p>A PLUG poderá alterar esta Política de Privacidade a qualquer tempo e sem aviso prévio para que possamos estar atualizados às melhores práticas de privacidade. Sempre que houver alguma alteração desta Política de Privacidade, essa alteração será válida, eficaz e vinculante logo após a nova versão ser divulgada em nossos Sites e/ou Aplicativos. Desta forma, recomendamos visitas periódicas à esta página para que o Usuário tome conhecimento da versão mais atualizada desta Política. Caso julgue necessário, a PLUG poderá enviar comunicado informando sobre alguma alteração relevante desta Política de Privacidade.</p>
            <p>Última atualização em 18 de julho de 2024.</p>
        </div>
    );
}

export default Privacidade;
