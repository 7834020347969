import React from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import Navbar from '../component/navbar';
import Privacidade from '../component/privacidade';
import Footer from '../component/footer';

function Privacy() {
    return (
        <>
            <MDBContainer fluid className='p-0'>
                <Navbar />
                <Privacidade />
                <Footer />
            </MDBContainer>
        </>
    )
}

export default Privacy;
