import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn } from 'mdb-react-ui-kit';
import '../styles/vender.css';
import card1 from '../img/vender/card1.png';
import card2 from '../img/vender/card2.png';
import card3 from '../img/vender/card3.png';

const cardContents = [
    {
        title: 'Proteção financeira para seu cliente',
        text: 'O seguro garantia estendida representa um excelente custo-benefício! Quase sempre o custo de um único reparo do equipamento não segurado é maior que o valor pago pelo prêmio do seguro garantia estendida.',
        image: card1
    },
    {
        title: 'Fidelização do cliente',
        text: 'Seu cliente vai lembrar de você enquanto o seguro estiver vigente e vai lhe agradecer se precisar acionar o seguro para realizar um reparo ou até mesmo a troca do produto.',
        image: card2
    },
    {
        title: 'Receita adicional para sua loja',
        text: 'A remuneração que lhe pagaremos pode representar um aumento de 30% a 40% na margem do seu negócio! Uma receita adicional sem precisar aumentar nenhum custo, nem de pessoal nem de administração (aluguel, luz, telefone, etc).',
        image: card3
    }
];

function PorQueVender() {
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    return (
        <MDBContainer className="por-que-vender-section" id='vender'>
            <h2>Por que Vender</h2>
            <h4>Porque vender o seguro Garantia Estendida? Entenda os Benefícios:</h4>
            <MDBRow>
                {cardContents.map((content, index) => (
                    <MDBCol md="4" key={index}>
                        <MDBCard
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            className="vender-card"
                        >
                            <div className='card-icon'>
                                <img src={content.image} alt={`Card ${index + 1}`} />
                            </div>
                            <MDBCardBody>
                                <MDBCardTitle>{content.title}</MDBCardTitle>
                                <MDBCardText className={`card-text ${hoveredCard === index ? 'expanded' : 'collapsed'}`}>
                                    {hoveredCard === index ? content.text : truncateText(content.text, 15)}
                                </MDBCardText>
                                {hoveredCard !== index && <div className="card-ellipsis">...</div>}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                ))}
            </MDBRow>
            <MDBRow className='vender-sim-wrapper'>
                <MDBBtn href='#simulacao' className='vender-sim' color="primary">Faça uma simulação</MDBBtn>
            </MDBRow>
        </MDBContainer>
    );
}

function truncateText(text, wordLimit) {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
}

export default PorQueVender;
