import React from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import Navbar from '../component/navbar';
import Hero from '../component/hero';
import EntendendoGarantia from '../component/garantia';
import PorQueVender from '../component/vender';
import ProdutosElegiveis from '../component/produtos';
import Parceria from '../component/parceria';
import QuemSomos from '../component/quemSomos';
import QueroFalar from '../component/queroFalar';
import Footer from '../component/footer';
import CookieNotice from '../component/cookieNotice';

function Index() {
    return (
        <>
            <MDBContainer fluid className='p-0'>
                <Navbar />
                <Hero />
                <EntendendoGarantia />
                <PorQueVender />
                <ProdutosElegiveis />
                <Parceria />
                <QuemSomos />
                <QueroFalar />
                <Footer />
                <CookieNotice />
            </MDBContainer>
        </>
    );
}

export default Index;
