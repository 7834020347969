import React from 'react';
import { MDBContainer} from 'mdb-react-ui-kit';
import '../styles/garantia.css';

function EntendendoGarantia() {
    return (
        <MDBContainer fluid className="entendendo-garantia-section" id='garantia'>
            <h2>Entendendo a Garantia Estendida</h2>
            <h4>E o que é o Seguro Garantia Estendida?</h4>
            <p>De forma simples, é um seguro que aumenta ou estende o prazo de garantia da fábrica para produtos vendidos no varejo.</p>
            <p>Ou seja, é um seguro que aumenta o tempo de proteção oferecido pelo fornecedor para bens contra defeitos de funcionamento cobrindo custos de peças e da mão de obra utilizados em seu conserto.</p>

            <div className="garantia-content">
                <h4>Para que serve o seguro Garantia Estendida?</h4>
                <p>Segundo a SUSEP (Superintendência de Seguros Privados), o seguro de Garantia Estendida tem como objetivo fornecer ao segurado, facultativamente e mediante o pagamento de prêmio, a extensão temporal da garantia do fornecedor de um bem adquirido e, quando prevista, sua complementação.</p>
                <p><b style={{ fontSize: '0.9rem' }}>Fonte: https://www.gov.br/susep/pt-br/planos-e-produtos/seguros/seguro-de-garantia-estendida</b></p>
            </div>
            <div className="garantia-container">
                <div className="garantia-content">
                    <h4>Você sabia?</h4>
                    <h6>O seguro garantia estendida pode salvar seu cliente daquele perrengue quando a TV quebra e garantia de fábrica já expirou!</h6>
                    <p className='mt-3'>O custo de um único conserto pode ser maior que o valor pago pelo seguro.
                        <span>Sim, é verdade! O custo de reparo, somando peças e serviço de assistência técnica especializada, pode facilmente variar entre 20 e 40% do preço do produto.</span>
                        <span>Enquanto o custo do prêmio do seguro garantia estendida normalmente varia entre 10% a 20% do valor do produto comprado.</span></p>
                </div>
            </div>
        </MDBContainer>
    );
}

export default EntendendoGarantia;
