import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa';
import '../styles/footer.css';
import privacidade from '../doc/politicas-de-privacidade.pdf'

const Footer = () => {
  return (
    <footer className="footer">
      <MDBContainer fluid className="py-2">
        <MDBRow className='justify-content-center align-items-center'>
          <MDBCol md="4" className="footer-left">
            <a href='/privacidade' target='_blank' rel='noreferrer' className="footer-link color-white">Políticas de Privacidade</a>
          </MDBCol>
          <MDBCol md='4'>
            <p className='m-0 text-center'>Fornecido por: <br />Plug Promotora de Vendas <br /> CNPJ – 26.211.588/0001-16.</p>
          </MDBCol>
          <MDBCol md="4" className="footer-right">
            <a href="https://www.facebook.com" className="social-icon text-white me-4">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com" className="social-icon text-white me-4">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" className="social-icon text-white me-4">
              <FaLinkedin />
            </a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </footer>
  );
}

export default Footer;
