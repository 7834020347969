import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import InputMask from 'react-input-mask';
import { submitForm } from '../api_controller/submitData';
import '../styles/queroFalar.css';
import icon from '../img/queroFalar/falar-card-icon.svg';

function QueroFalar() {
  const [step, setStep] = useState(1);
  const [isCelular, setIsCelular] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
    cargo: '',
    nomeEmpresa: '',
    segmentoVarejo: '',
    uf: '',
    quantasLojas: '',
    quantosVendedores: '',
    produtosVendidos: ''
  });
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  const ufs = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"
  ];

  const segmentosVarejo = [
    "Audio, Imagem & Vídeo",
    "Fitness & Saúde Digital",
    "Computadores, smartphones & eletrônicos",
    "Eletrodomésticos & eletroportáteis",
    "Eletromóveis",
    "Móveis",
    "Outros"
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleNext = () => {
    if (formData.nome && formData.telefone && formData.email && formData.cargo && validateEmail(formData.email)) {
      setEmailError('');
      setStep(2);
    } else {
      setEmailError('Por favor, insira um e-mail válido.');
    }
  };

  const handleClearInputs = () => {
    setFormData({
      nome: '',
      telefone: '',
      email: '',
      cargo: '',
      nomeEmpresa: '',
      segmentoVarejo: '',
      uf: '',
      quantasLojas: '',
      quantosVendedores: '',
      produtosVendidos: ''
    });
    setEmailError('');
    setLoading(false);
  };

  const handleSend = async () => {
    if (
      formData.nomeEmpresa &&
      formData.segmentoVarejo &&
      formData.uf &&
      formData.quantasLojas &&
      formData.quantosVendedores &&
      formData.produtosVendidos
    ) {
      setLoading(true);
      try {
        const response = await submitForm(
          formData.nome,
          formData.telefone,
          formData.email,
          formData.cargo,
          formData.nomeEmpresa,
          formData.segmentoVarejo,
          formData.uf,
          formData.quantasLojas,
          formData.quantosVendedores,
          formData.produtosVendidos
        );

        if (response.success) {
          alert('Contato recebido com sucesso!');
          handleClearInputs();
          setStep(1);
        } else {
          alert('Ops, parece que houve um erro, tente novamente!');
          setLoading(false);
        }
      } catch (error) {
        console.error('Erro ao enviar os dados:', error);
        setLoading(false);
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleCheckboxChange = () => {
    setIsCelular(!isCelular);
  };

  const getPhoneMask = () => {
    return isCelular ? "(99) 9 9999-9999" : "(99) 9999-9999";
  };

  return (
    <MDBContainer className="quero-falar-section" id='queroFalar'>
      <MDBRow className='quero-falar-section-reverse'>
        <MDBCol md="6">
          <MDBCard className='quero-falar-card'>
            <div className='falar-icon-wrapper'>
              <img src={icon} alt="Icon" />
            </div>
            <MDBCardBody>
              {step === 1 && (
                <>
                  <MDBInput 
                    label="Nome" 
                    name="nome" 
                    type='text' 
                    value={formData.nome} 
                    onChange={handleChange} 
                    className="mb-3 white-label" 
                    maxLength="50"
                    required
                  />
                  <MDBRow className='mb-3'>
                    <MDBCol size="9">
                      <InputMask
                        mask={getPhoneMask()}
                        value={formData.telefone}
                        onChange={handlePhoneChange}
                        required
                      >
                        {() => <MDBInput label="Telefone" name="telefone" type='tel' className="white-label" />}
                      </InputMask>
                    </MDBCol>
                    <MDBCol size="3" className="d-flex align-items-center">
                      <div className="form-check">
                        <input 
                          type="checkbox" 
                          className="form-check-input" 
                          id="celularCheck" 
                          checked={isCelular} 
                          onChange={handleCheckboxChange} 
                        />
                        <label className="form-check-label" htmlFor="celularCheck">
                          Celular
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBInput 
                    label="E-mail" 
                    name="email" 
                    type='email' 
                    value={formData.email} 
                    onChange={handleChange} 
                    className="mb-3 white-label" 
                    required
                  />
                  {emailError && <div className="text-danger">{emailError}</div>}
                  <MDBInput 
                    label="Cargo" 
                    name="cargo" 
                    type='text' 
                    value={formData.cargo} 
                    onChange={handleChange} 
                    className="mb-3 white-label" 
                    maxLength="50"
                    required
                  />
                  <div className="d-flex justify-content-end">
                    <MDBBtn 
                      color="primary" 
                      onClick={handleNext} 
                      disabled={!formData.nome || !formData.telefone || !formData.email || !formData.cargo || !validateEmail(formData.email)}
                    >
                      Próximo
                    </MDBBtn>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <MDBInput 
                    label="Nome da Empresa" 
                    name="nomeEmpresa" 
                    type='text' 
                    value={formData.nomeEmpresa} 
                    onChange={handleChange} 
                    className="mb-2 white-label" 
                    maxLength="50"
                    required
                  />
                  <select 
                    name="segmentoVarejo" 
                    value={formData.segmentoVarejo} 
                    onChange={handleChange} 
                    className="mb-2 white-label form-select"
                    required
                  >
                    <option value="">Selecione o Segmento de Varejo</option>
                    {segmentosVarejo.map((segmento) => (
                      <option key={segmento} value={segmento}>{segmento}</option>
                    ))}
                  </select>
                  <select 
                    name="uf" 
                    value={formData.uf} 
                    onChange={handleChange} 
                    className="mb-2 white-label form-select"
                    required
                  >
                    <option value="">Selecione a UF</option>
                    {ufs.map((uf) => (
                      <option key={uf} value={uf}>{uf}</option>
                    ))}
                  </select>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBInput 
                        label="Quantas Lojas" 
                        name="quantasLojas" 
                        type='number' 
                        value={formData.quantasLojas} 
                        onChange={handleChange} 
                        className="mb-2 white-label" 
                        required
                        min="0"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput 
                        label="Quantos Vendedores" 
                        name="quantosVendedores" 
                        type='number' 
                        value={formData.quantosVendedores} 
                        onChange={handleChange} 
                        className="mb-2 white-label" 
                        required
                        min="0"
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBInput 
                    label="Produtos que eu vendo" 
                    name="produtosVendidos" 
                    value={formData.produtosVendidos} 
                    onChange={handleChange} 
                    className="mb-3 white-label" 
                    textarea 
                    rows={2} 
                    maxLength="200"
                    required
                  />
                  <div className="d-flex justify-content-end">
                    <MDBBtn 
                      color="primary" 
                      onClick={handleSend} 
                      disabled={loading || !formData.nomeEmpresa || !formData.segmentoVarejo || !formData.uf || !formData.quantasLojas || !formData.quantosVendedores || !formData.produtosVendidos}
                    >
                      {loading ? 'Enviando...' : 'Enviar'}
                    </MDBBtn>
                  </div>
                </>
              )}
            </MDBCardBody>
            <div className='falar-bg-wrapper'>
              <div className='falar-card-bg'></div>
              <div className='falar-card-bg2'></div>
            </div>
          </MDBCard>  
        </MDBCol>
        <MDBCol md="6" className='d-flex justify-content-center flex-column falar-text-wrapper'>
          <h2>Fale com nosso time</h2>
          <p>Preencha os campos ao lado e entre em contato com um de nossos consultores para sanar todas as suas dúvidas!</p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default QueroFalar;
