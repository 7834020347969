import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import '../styles/quemSomos.css';
import img1 from '../img/quemSomos/cell1.png';
import img2 from '../img/quemSomos/cell2.png';
import img3 from '../img/quemSomos/cell3.png';

function QuemSomos() {
  return (
    <>
      <div className='quem-somos-top'></div>
      <MDBContainer fluid className="quem-somos-section" id='quemSomos'>
        <MDBContainer>
          <MDBRow className="align-items-center justify-content-center">
            <MDBCol md="6" className='img-container'>
              <img src={img1} alt="Imagem 1" className="img-fluid img-middle" />
              <img src={img2} alt="Imagem 2" className="img-fluid img-left" />
              <img src={img3} alt="Imagem 3" className="img-fluid img-right" />
            </MDBCol>
            <MDBCol md="6" className='text-justify'>
              <h2 className='text-center'>Quem somos</h2>
              <p>Somos a Plug, uma distribuidora de produtos digitais com larga experiência tanto no  mundo dos seguros como na oferta de produtos digitais no varejo.</p>
              <p>Nós conectamos sua loja às melhores seguradoras do Brasil.</p>
              <p>Temos a melhor tecnologia para lhe ajudar na venda do seguro em sua loja. Tudo muito  simples e rápido! Em poucos cliques a venda é concluída e seu vendedor já está livre para  atender o próximo cliente!</p>
              <p>Daremos todo o suporte aos seus vendedores e ferramentas para você acompanhar a  produção de seu time.</p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </>
  );
}

export default QuemSomos;
