import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import '../styles/produtos.css';
import appliancesImg from '../img/produtos/Home-Appliance-Background-PNG.png';

function ProdutosElegiveis() {
  return (
    <MDBContainer fluid className='produtos-wrapper' id='produtos'>
      <div className='produtos-bg'></div>
      <div className='produtos-inner-bg'></div>
      <MDBContainer className="produtos-elegiveis-section">
        <MDBRow>
          <MDBCol md="7" className='produtos-text-wrapper'>
            <h2>Produtos elegíveis para o Seguro GE</h2>
            <h4>Quais produtos podem ser segurados com o seguro garantia estendida?</h4>
            <p>Equipamentos elétricos e eletrônicos em geral, tais como, linha branca (geladeira, fogão,  máquina de lavar, freezer, outros), linha marrom (TVs, video-game,  equipamentos áudio-visual, outros),  ar condicionado, pequenos motores, computadores, impressoras, smartphones e etc</p>
            <p>Até móveis como sofás e mesas podem ter a garantia de fábrica estendida com o seguro  GE!</p>
          </MDBCol>
          <MDBCol md="5">
            <img src={appliancesImg} alt="Placeholder" className="img-fluid produtos-img" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
}

export default ProdutosElegiveis;
