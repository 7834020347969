import api from "./api";

async function submitForm(nome, telefone, email, cargo, nomeEmpresa, segmentoVarejo, uf, quantasLojas, quantosVendedores, produtosVendidos) {
    const formData = { nome, telefone, email, cargo, nomeEmpresa, segmentoVarejo, uf, quantasLojas, quantosVendedores, produtosVendidos };

    try {
        const response = await fetch(`${api}/submit`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        });

        const data = await response.json();

        if (data.status === 'success') {
            return { success: true };
        } else {
            return { success: false };
        }
    } catch (error) {
        console.error("Error:", error);
        return { success: false };
    }
}

async function saveSimulation(simulationData) {
    try {
        const response = await fetch(`${api}/simulation/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(simulationData),
        });

        return await response.json();
    } catch (error) {
        console.error('Erro ao salvar a simulação:', error);
        throw error;
    }
}

export { submitForm, saveSimulation };
